.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.Body-container {
  margin-top: 10px;
  padding-top: 64px;
}

.tab-renderer-preview-table td:first-child {
  padding-left: 10px;
  padding-right: 20px;
  vertical-align: top;
}
